@import "../variables";
@import "../template/mixin_templates";
@import "../../foundation/functions";

/* Général */
body{
    background-color: $body_background_color;
    color:$texte_color;
}
.content_p, body .wrapper.accueil_diaporama_template{
    background-color: $content_background_color;
}
.header_p{
    background-color: $header_background;
}
h2, .h2{
    color:$titre_color;
    border-left-color:$titre_border_color;
    &::before{
        background-color:$titre_border_color;
    }
}
a{
    color:$texte_lien;
    &:hover{
        color:$texte_lien_hover;
    }
}

.bouton, .button, input[type="submit"], .close-menu.ouvert, .bx-controls-direction a.bx-next, .bx-controls-direction a.bx-prev,
body .produits-accueil.template_boutique_accueil.accueil_boutique_template_1 .bx-wrapper .bx-controls-direction a.bx-next,
body .produits-accueil.template_boutique_accueil.accueil_boutique_template_1 .bx-wrapper .bx-controls-direction a.bx-prev,
body .template_fiche_produit_3 #fiche-produit .wrap-description .side-tabs a,
body .banner_diaporama .bx-wrapper .bx-next, body .banner_diaporama .bx-wrapper .bx-prev
{
    border-color:$button_border;
    background-color: $button_background;
    color:$button_color;
    background-image:none !important;
    &:hover{
        background-color: $button_background_hover;
        color:$button_color_hover;
        border-color:$button_border_hover;
        &::before, &::after{
            color:$button_color_hover;
        }
    }
}
.close-menu.ferme{
    background-color: $button_background_hover;
    color:$button_color_hover;
    border-color:$button_border_hover;
    &::before, &::after{
        color:inherit;
    }
}
.hover .button_galerie{
    background-color: $arrow_background_hover;
    border-color:$arrow_background_hover;
}

/* site en deux colonnes */
.sidebar{
    border:1px solid $colonne_gauche_border;
}

/* Header */

header{
    background-color:$header_background;
}
.slogan{
    color:$slogan_color_texte;
}

/* fil d'ariane */

#bloc-fil-title *{
    color:$gros_titre_color;
}
nav.arianne li a, nav.arianne li::before,
h2.title_section, .h2.title_section, h1.title_section, .h1.title_section,
.titre_page h2, .titre_page .h2, .titre_page h1, .titre_page .h1{
    color:$gros_titre_color;
}
#bloc-fil-title{
    &, &::before{
        background-color:$gros_titre_background_color;
    }
}

/* Menu */
.menu{
    background:transparent;
}
#nav-principal{
    background-color:$nav_background_color;
    li{
        background-color: $li_background_color;
        color:$nav_texte_color;
        border-color:$li_border_color !important;
        a{
            color:$nav_texte_color;
        }
        span.dropDown{
            color:inherit;
            background:transparent !important;
        }
        &.actif{
            background-color:$li_background_color_hover;
            color:$nav_texte_color_hover;
            > a{
                color:$nav_texte_color_hover;
            }
        }
    }
    li:hover, li.sousmenu_ouvert{
        background-color:$li_background_color_hover;
        color:$nav_texte_color_hover;
        > a{
            color:$nav_texte_color_hover;
        }
        span.dropDown{
            color:inherit;
            background:transparent !important;
        }
    }
    > ul > li{
        &.has_picto > a > span > i, &:not(.has_picto) > a::before{
            color:$icone_color;
            background-color:transparent;
        }
        > a{
            border-left-color:$icone_background_color;
        }
        &:hover > a, &.actif > a, &.sousmenu_ouvert > a{
            border-left-color:$icone_background_color_hover;
        }
        > a::after{
            border-left-color:$icone_background_color;
        }
        &:hover > a::after, &.actif > a::after, &.sousmenu_ouvert > a::after{
            border-left-color:$icone_background_color_hover;
        }
        &.has_picto:hover > a > span > i, &.actif.has_picto > a > span > i, &.sousmenu_ouvert.has_picto > a > span > i,
        &:not(.has_picto):hover > a::before, &.actif:not(.has_picto) > a::before, &.sousmenu_ouvert:not(.has_picto) > a::before{
            color:$icone_color_hover;
        }
    }
}

/* Template Panier */

body.cmonsite-panier-2 #nav-principal > ul {
    + .template-panier.template-panier-2 {
        border-color:$li_border_color;
        #panier #header-panier > i{
            background:$icone_background_color_hover;
            color:$icone_color_hover;
        }
    }
}

.template-panier{
    background-color:$panier-background-color;
    color:$panier-titre-color;
    .connexion p{
        border-color:$panier-titre-color;
        &, a{
            color:$panier-titre-color;
        }
    }
    #header-panier{
        color:$panier-titre-color;
    }
    #panier #paniercontent{
        background-color:$panier-content-background-color;
        &, #paniertable{
            color:$panier-texte-color;
        }
        border-color:$panier-background-color;
    }
    &.template-panier-1{
        #panier #paniercontent{
            &, #paniertable{
                color:$panier-titre-color;
            }
        }
    }
    .count-live{
        background:$panier-content-background-color;
        color:$panier-texte-color;
    }
}

/* Produits */

.produits{
    background:$background_produit;
    color:$texte_produits;
    &:hover{
        background:$background_produit_hover;
    }
    .nomprod a{
        color:$texte_titre_produits;
    }
    .remise{
        background:$remise_background_color;
        color:$remise_texte_color;
    }
    .prix{
        border-color:$border_produit !important;
    }
}

.template_fiche_produit_1 #fiche-produit .top_fiche .wrap-description .ajouter-panier .triangle-ficheprod-second{
    border-right-color:$triangle_border_right;
}

/* Fiche produit */

#fiche-produit .triangle-ficheprod-second .remise-produit,
#fiche-produit .triangle-ficheprod .remise-produit{
    background:$remise_background_color;
    color:$remise_texte_color;
}

.addbasket, .produits .addbasket .inside-addbasket, .produits .mask .zoom, .productImageWrap > .zoom, #fiche-produit .template_boutique .produits .productImageWrap > .zoom, .template_boutique .produits .productImageWrap > .zoom{
    background-color: $background_addbasket;
    color:$color_addbasket;
    &::before, &::after{
        color:$color_addbasket;
    }
    &:hover{
        background-color: $background_addbasket_hover;
        color:$color_addbasket_hover;
        &::before, &::after{
            color:$color_addbasket_hover;
        }
    }
}

.produit_etiquette,.produit_etiquette2,.produit_etiquette3{
    background:$etiquette_produit_background;
    h3, .h3{
        color:$etiquette_produit_texte;
        background:$etiquette_produit_background;
    }
}
.produit_etiquette::before{
    background-color:$etiquette_produit_background;
    color:$etiquette_produit_texte;
}

.template_fiche_produit #fiche-produit{
    .top_fiche{
        border-color:$fiche_produit_border;
        .prix{
            color:$remise_texte_color;
        }
        .previews .previous, .previews .next{
            background:$arrow_background;
            color:$arrow_color;
            &:hover{
                background:$arrow_background_hover;
                color:$arrow_color_hover;
            }
        }
    }

    .ajouter-panier .addbasket{
        background-color: $content_background_color;
        color:$texte_color;
        &:hover, &:focus{
            color:$remise_background_color;
        }
    }
    .bloc-quantite{
        input, button{
            background-color: $content_background_color;
            color:$texte_color;
        }
    }
    .radio_unique{
        background-color: $button_background;
        color:$button_color;
        &:hover{
            background-color: $button_background_hover;
            color:$button_color_hover;
        }
    }
    .wrap-description{
        border-color:$fiche_produit_border !important;
    }
    .ajouter-panier{
        background-color:$background_achat;
    }
}

.template_fiche_produit #fiche-produit .top_fiche .button {
    background-color: $content_background_color;
    color: $texte_color;
    &:hover, &:focus{
        color:$remise_background_color;
    }
}

.template_fiche_produit_1 #fiche-produit .avec-declinaison .declinaison{
    border: $fiche_produit_border;
}
.template_fiche_produit_3 #fiche-produit .top_fiche .prix{
    color:$remise_texte_color_3;
}

/* Galerie photo */

.galerie{
    background:$mask_background;
    .mask{
        background:$mask_background;
    }
    .addcommentaires, .commentP{
        color:$galerie_texte_color;
    }
    .bloc-titre-galerie p, .commentP p, .bloc-cache-overflow p{
        color:$galerie_texte_color;
        &::before{
            background-color:$galerie_texte_color;
        }
    }
}

/* Livre d'or */

.template_livreor{
    h4, .note, .h4{
        color:$message_lo_color;
    }
}
.template_livreor_1 .message_lo{
    border-color:$message_lo_color;

}
body .template_livreor_2 .list-messages .message_lo{
    .villePaysLo{
        color:$message_lo_color;
    }
    .IDLO{
        border-left-color:$message_lo_color;
    }
}
.template_livreor_3 .message_lo{
    .message::before, .message::after{
        color:$message_lo_color;
    }
}

/* Blog / Boutons */

.pagination{
    >li > a{
        background-color:$btn_texte_color;
        color:$btn_background_color;
    }
    li.active a{
        background-color:$btn_background_color;
        border-color:$btn_background_color;
        color:$btn_texte_color;
    }
}

/* Slider */

body .accueil_diaporama_template{
    .slide .slide-text{
        p{
            background:$background_texte !important;
            color:$color_texte_slider !important;
        }
        > a.addcommentaires, a{
            background:$arrow_background;
            color:$arrow_color;
            &:hover{
                background:$arrow_background_hover;
                color:$arrow_color_hover;
            }
        }
    }
    .bx-wrapper .bx-controls-direction a{
        background:$arrow_background;
        color:$arrow_color;
        i, i::before{
            color:$arrow_color;
        }
        &:hover{
            background:$arrow_background_hover;
            color:$arrow_color_hover;
        }
    }
}

/* Footer */

footer{
    background-color:$footer_background_color;
    color: $footer_texte_color;
    p{
        color: $footer_texte_color;
    }
    a{
        color:$footer_link_color;
    }
}
.fleche_top_site{
    background-color:$footer_background_color;
}

/* Responsive */

@media #{$small-and-down} {
    #nav-principal>ul ul li{
        background:$li_background_color_hover;
        a:not(.menu_burger){
            color:$nav_texte_color_hover;
        }
    }
    .menu_burger{
        background:$burger_background_color;
        color:$burger_texte_color;
    }
    #nav-principal.nav_fermee .menu_burger{
        background:$burger_background_color_ouvert;
        color:$burger_texte_color_ouvert;
    }
    .dropDown {
        color:inherit;
    }
    li:hover,li.actif{
        > .dropDown {
            color:inherit;
        }
    }
}

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: $couleur_secondaire;
    border-color: $couleur_secondaire;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: $couleur_secondaire;
    color: $couleur_secondaire;

    a, span {
        color: $couleur_secondaire;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}